import React from 'react';
import BurgerMenu from '../../commons/BurgerMenu';
import { useTranslation } from 'react-i18next';
import NotificationsTable from './NotificationsTable';
import { ContFilterYear, ContPaginationPage } from '../../styled-components';
import { Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import Header from '../../commons/Header';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import SearchInput from '../../commons/SearchInput';

const NotificationsPresentational = ({
  data,
  onFilter,
  query,
  total,
  profile,
  offset,
  limit,
}) => {
  const [t] = useTranslation('NOTIFICATIONS');

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:NOTIFICATIONS')}
            icon={<IconInvoices fill="#ffffff" style={{ height: '25px' }} />}
          />
        </section>

        <Row
          className="search-add-cont m-t-20 d-flex"
          style={{ rowGap: '10px' }}
        >
          <Col sm="12" md="6" lg="3" xl="3">
            <SearchInput
              query={{ search: query && query.sender }}
              onFilter={({ search }) => onFilter({ sender: search })}
              label={t('SEARCH:NAME')}
            />
          </Col>

          <Col sm="12" md="6" lg="3">
            <ContFilterYear className="no-width">
              <Row>
                <Col xs="6">
                  <Label>Visualizzato</Label>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <CustomInput
                      type="select"
                      id="status"
                      name="status"
                      onChange={(e) =>
                        onFilter({
                          status: e.target.value,
                        })
                      }
                      value={query.status || ''}
                    >
                      <option value="" defaultChecked>
                        Tutti
                      </option>
                      <option value="TRUE">SI</option>
                      <option value="FALSE">NO</option>
                    </CustomInput>
                  </FormGroup>
                </Col>
              </Row>
            </ContFilterYear>
          </Col>
        </Row>

        <section style={{ marginTop: '10px' }}>
          <NotificationsTable
            data={data}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
            profile={profile}
          />

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
      </div>
    </div>
  );
};

export default NotificationsPresentational;
