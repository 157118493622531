import React, { useEffect, useState } from 'react';
import { useAuth } from '../../commons/Auth';
import { ReactComponent as IconLogout } from '../../images/icn_logout.svg';
import { ReactComponent as IconEmail } from '../../images/icn-email.svg';
import './Header.scss';
import variables from '../../variables';
import { useTranslation } from 'react-i18next';
import { ADMIN_TYPES } from '../../App';
import { profileScoreFormatter } from '../../lib/helpers/profileScoreFormatter';
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { useLoading } from '../Loading';
import { useAlert } from 'react-alert';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Header({ title, icon }) {
  const [, dispatch] = useAuth();
  const [t] = useTranslation('CONTACTS');
  const [{ token, profile, isAdmin }] = useAuth();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();
  let totalProfileScore = 0;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsNotReaded, setNotificationsNotReaded] = useState([]);

  const fetchNotifications = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/notifications`,
      {
        headers: { Authorization: token },
        params: {
          limit: 10,
          offset: 0,
        },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setNotifications(data.notifications);

    setNotificationsNotReaded(
      data.notifications.filter((notification) => notification.status === false)
    );
  };

  const updateNotifications = async () => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/notifications`,
      {},
      {
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    fetchNotifications();
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  if (ADMIN_TYPES.includes(profile?.type)) {
    const profileScore = profileScoreFormatter(
      profile?.profileScore?.[0] || {}
    );

    let totalTrainingCalculatedData = 0;
    for (
      let index = 0;
      index < profileScore?.trainingCourses?.length;
      index++
    ) {
      totalTrainingCalculatedData += Number(
        profileScore?.trainingCourses[index]?.credits
      );
    }
    if (totalTrainingCalculatedData > 100) totalTrainingCalculatedData = 100;

    if (profileScore) {
      totalProfileScore += profileScore.EONScore || 0;
      totalProfileScore += profileScore.insuranceScoreType1 || 0;
      totalProfileScore += profileScore.insuranceScoreType2 || 0;
      totalProfileScore += profileScore.supplierScore || 0;
      totalProfileScore += profileScore.trainingScore
        ? profileScore.trainingScore
        : totalTrainingCalculatedData;
    }
  }

  const logout = () => dispatch({ type: 'logout' });
  const adminLevels = [
    'LIVELLO TRIANGOLO',
    'LIVELLO QUADRATO',
    'LIVELLO PENTAGONO',
    'LIVELLO ESAGONO',
    'LIVELLO OTTAGONO',
  ];
  let adminLevel = '';
  if (profile.type !== undefined) {
    switch (profile.type) {
      case 'BUILDING_ADMIN_1':
        adminLevel = adminLevels[0];
        break;
      case 'BUILDING_ADMIN_2':
        adminLevel = adminLevels[1];
        break;
      case 'BUILDING_ADMIN_3':
        adminLevel = adminLevels[2];
        break;
      case 'BUILDING_ADMIN_4':
        adminLevel = adminLevels[3];
        break;
      case 'BUILDING_ADMIN_5':
        adminLevel = adminLevels[4];
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (dropdownOpen) {
      updateNotifications();
    }
  }, [dropdownOpen]);

  return (
    <div className="header-crm">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-8">
          <h2>
            {icon}
            {title}
          </h2>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="cont-header-element">
            {/* <Lang /> */}
            <button onClick={() => logout()} className="btn btn-logout">
              <IconLogout fill={variables.primary} />
            </button>
            {!isAdmin && (
              <p>
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle color="primary" className="btn btn-logout">
                    <IconEmail fill={variables.primary} />
                    {notificationsNotReaded.length > 0 && (
                      <Badge color="danger" pill>
                        {notificationsNotReaded.length}
                      </Badge>
                    )}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {notifications.length === 0 ? (
                      <DropdownItem disabled>
                        Non ci sono notifiche
                      </DropdownItem>
                    ) : (
                      notifications.map((notification) => (
                        <DropdownItem key={notification._id}>
                          <Link
                            to={`/notifications`}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            {notification.title}
                          </Link>
                        </DropdownItem>
                      ))
                    )}
                  </DropdownMenu>
                </Dropdown>
              </p>
            )}
            {ADMIN_TYPES.includes(profile.type) && (
              <p className="credits">
                {adminLevel} - CREDITI: {totalProfileScore || 0}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
