import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getSignedUrl } from '../../../lib/fetch/files';
import { deleteSuppAdminAgreement } from '../../../lib/fetch/agreements';
import SupplierAdminAgreementsPresentational from './SupplierAdminAgreementsPresentational';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const SupplierAdminAgreements = (props) => {
  const [t] = useTranslation('CONTRACTS_AREA');
  const [{ token, profile = {} } = {}] = useAuth();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  // Stepper 1
  const [activities, setActivities] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [agreementTypes, setAgreementTypes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  // Stepper 2
  const [documents, setDocuments] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  // Parametri Legati ai Documenti
  const [queryDocuments, setQueryDocuments] = useQueryParams({
    limitDocuments: withDefault(NumberParam, 10),
    offsetDocuments: NumberParam,
  });
  const { limitDocuments, offsetDocuments } = queryDocuments;

  function onFilterDocuments(queryDiff) {
    if (
      queryDiff.limitDocuments &&
      queryDiff.limitDocuments !== limitDocuments
    ) {
      queryDiff.offsetDocuments = 0;
    }
    setQueryDocuments(queryDiff);
  }

  // Fetch attivitá per Select Attivitá
  const fetchActivities = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/activities`,
      {
        headers: { Authorization: token },
        params: {
          limit: 9999,
          offset: 0,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setActivities(data.activities);
  };

  // Fetch attivitá per Select Attivitá
  const fetchDocumentTypes = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/document-types`,
      {
        headers: { Authorization: token },
        params: {
          limit: 9999,
          offset: 0,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setDocumentTypes(data.documentTypes);
  };

  // Fetch attivitá per Select Attivitá
  const fetchAgreementTypes = async (activityId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agreement-types`,
      {
        headers: { Authorization: token },
        params: {
          limit: 9999,
          offset: 0,
          activityId,
        },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setAgreementTypes(data.agreementTypes);
  };

  // Fetch Fornitori per Select Fornitori
  const fetchSuppliers = async (activityId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agreements/suppliers`,
      {
        headers: { Authorization: token },
        params: { activityId },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setSuppliers(data.suppliers);
  };

  // Fetch Documenti
  async function fetchDocuments() {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/documents/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          limit: limitDocuments,
          offset: offsetDocuments,
          needed: true,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers } = response;

    setDocuments(data.documents);
    setTotalDocuments(+headers['x-total-count']);
  }

  const onSelectChange = (_id) => {
    if (selectedDocuments.includes(_id)) {
      setSelectedAll(false);
      return setSelectedDocuments((selectedDocuments) =>
        selectedDocuments.filter((value) => value !== _id)
      );
    }
    setSelectedDocuments((selectedDocuments) => [...selectedDocuments, _id]);
  };

  const onSelectAll = (selectAll) => {
    setSelectedAll(selectAll);
    if (!selectAll) {
      return setSelectedDocuments([]);
    }
    setSelectedDocuments(
      documents
        // .filter((document) => document._id !== undefined)
        .map(({ _id }) => _id)
    );
  };

  async function handleFileListView(key) {
    showLoading();

    const { data, error } = await getSignedUrl(key, token);

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    window.open(data.url, '_blank');
  }

  async function handleDelete({ agreementId }) {
    showLoading();

    const { error, data } = await deleteSuppAdminAgreement({
      agreementId,
      token,
    });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    alert.success(t('COMMON:SUCCESS_DELETE'));
  }

  useEffect(() => {
    fetchActivities();
    fetchDocumentTypes();
  }, []);

  useEffect(() => {
    fetchDocuments();
  }, [limitDocuments, offsetDocuments]);

  return (
    <SupplierAdminAgreementsPresentational
      activities={activities}
      documentTypes={documentTypes}
      agreementTypes={agreementTypes}
      suppliers={suppliers}
      fetchSuppliers={fetchSuppliers}
      fetchAgreementTypes={fetchAgreementTypes}
      documents={documents}
      totalDocuments={totalDocuments}
      limitDocuments={limitDocuments}
      offsetDocuments={offsetDocuments}
      onFilterDocuments={onFilterDocuments}
      onSelectChange={onSelectChange}
      onSelectAll={onSelectAll}
      selectedAll={selectedAll}
      selectedDocuments={selectedDocuments}
      handleFileListView={handleFileListView}
      handleDelete={handleDelete}
      fetchDocuments={fetchDocuments}
      {...props}
    />
  );
};

export default SupplierAdminAgreements;
